<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-notes"/>
      <h5 class="d-inline ml-2">
        {{ this.$route.meta.label }}
        <template>
          : <h3 class="d-inline"><CBadge color="secondary">{{ this.itemName }}</CBadge></h3>
        </template>
      </h5>
      <div class="card-header-actions">
        <CButton color="dark" :to="{ name: 'All Users'}">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <alert-section
        :successAlertMessage="successAlertMessage"
        :dismissSecs="dismissSecs"
        :dismissSuccessAlert="dismissSuccessAlert"
        :errorAlertMessage="errorAlertMessage"
        :showErrorAlert="showErrorAlert"
      />

      <CForm>
        <CRow>
          <CCol lg="12">
            <div class="form-group w-100">
              <label class="d-block">User Type:</label>
              <CInputRadioGroup
                name="usertype"
                :options="['User', 'Admin']"
                :checked.sync="$v.form.usertype.$model"
                custom
                inline
              />
            </div>
          </CCol>

          <CCol lg="6">
            <div class="form-group w-100">
              <label class="d-block">Roles:</label>
              <v-select
                v-model="$v.form.roles.$model"
                :options="allRoles"
                placeholder="No role selected!"
                label="name"
                :disabled="form.usertype === 'User'"
                :searchable="false"
                multiple
              >
                <template v-slot:option="roles">
                  <span>{{ roles.name }}</span>
                </template>
              </v-select>
            </div>
          </CCol>

          <CCol lg="6">
            <CInput
              class="w-100"
              label="2FA Mobile Number:"
              :lazy="false"
              :value.sync="$v.form.tfamobile.$model"
            >
              <template #prepend-content><CIcon name="cil-mobile" /></template>
            </CInput>
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>

    <CCardFooter>
      <CButton
        color="primary"
        :disabled="submitted"
        @click="update"
      >
        Submit
      </CButton>
    </CCardFooter>

    <CElementCover :opacity="0.4" v-show="submitted"/>
  </CCard>

</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength } from "vuelidate/lib/validators"
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { Role } from "../../../router/role";

export default {
  name: 'EditUser',
  components: {
    vSelect,
  },
  data() {
    return {
      form: {},
      submitted: false,

      itemName: '',
      allRoles: [],

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,
    }
  },

  watch: {
    'form.usertype': function(newVal, oldVal) {
      if (newVal === 'User' ) {
        this.form.roles = []
      }
    },
  },

  async created () {
    this.form = await this.getForm(this.itemId)
    this.allRoles = Object.keys(Role);
  },

  computed: {
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
    itemId() { return this.$route.params.id }
  },

  mixins: [validationMixin],

  validations: {
    form: {
      usertype: { required },
      tfamobile: { },
      roles: { },
    }
  },

  methods: {
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },

    update () {
      if (!this.isValid) {
        this.validate();
        return;
      }

      this.submitted = true

      this.$axios.put(this.$backend.USERS.UPDATE.replace("{id}", this.itemId), this.form)
        .then((response)=>{
          this.itemName = response.data.first_name
          this.successAlertMessage = {itemName: this.itemName, message: 'updated.'}
          this.dismissSuccessAlert = this.dismissSecs
          this.showErrorAlert = false
        })
        .catch((error)=>{
          this.errorAlertMessage = error.response.data
          this.showErrorAlert = true
        })
        .finally(() => {
          this.submitted = false
        });
    },

    validate () {
      //validate the form
      this.$v.$touch()
    },

    async getForm(id) {
      var form = {
        usertype: "",
        tfamobile: null,
        roles: [],
      }

      this.submitted = true
      await this.$axios.get(this.$backend.USERS.SHOW.replace("{id}", id))
        .then((res) => {
          form.usertype = res.data.usertype;
          form.tfamobile = res.data.tfamobile;
          form.roles = res.data.roles.map(r => { return r.name });

          this.itemName = res.data.first_name;
        })
        .catch((error) => {
          this.$router.push({ name: 'All Users' })
        })
        .finally(() => this.submitted = false);

      return form;
    }
  }
}
</script>
